.container {
    display: flex;
    flex-direction: column;
}

.container_input {
    display: flex;
    flex: 2;
}

.container_input > div {
    width: 100%;
}

.field {
    flex: 1;
    color: #5078BB !important;
    text-align: left !important;
    width: 100% !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}