.container_images {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: #5078BB !important;
    text-align: left !important;
    width: 100% !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.imageBox {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}