.section1_data {
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 24px;
}

.section1_data__text,
.section1_data__image {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 16px;
}

.section2_upload {
	display: flex;
	flex: 0 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.submitButton {
	background-color: #5078BB !important;
}

@media (max-width: 800px) {
	.section1_data {
		flex-direction: column-reverse;
		gap: 16px;
	}

	.section1_data__text,
	.section1_data__image {
		gap: 8px;
	}
}
